.App {
  text-align: center;
  background: rgba(16, 14, 23, 0.46);
  background: linear-gradient(90deg, rgb(16, 16, 26) 0%, rgb(12, 11, 21) 49%, rgb(16, 16, 26) 100%);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: #e5e5e5;
  overflow: auto;
}

.Added-gradient {
  background: rgb(54,20,88);
  background: radial-gradient(ellipse at center bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 76%);
}

.App-logo-bg {
  /*height: 40vmin;*/
  opacity: 0.01;
  animation: fade-bg 3s linear;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  background-size: cover;
}

.seVen-logo-initial {
  height: 128px;
  opacity: 0;
  animation: fade 2.6s linear;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 34vh;
}

.header-initial {
  opacity: 0;
  animation: fade-text 2.6s linear;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: calc(34vh + 130px);

}

.sub-initial {
  opacity: 0;
  animation: fade-text 2.6s linear;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: calc(34vh + 170px);

}

@keyframes fade-bg {
  0%,90% { opacity: 0 }
  100% { opacity: 0.01 }
}

@keyframes fade {
  0%,100% { opacity: 0 }
  20%,80% { opacity: 1 }
}

@keyframes fade-text {
  0%,100% { opacity: 0 }
  40%,80% { opacity: 1 }
}

/*@keyframes fade-text {*/
/*  0%,30%,100% { opacity: 0 }*/
/*  50%,80% { opacity: 1 }*/
/*}*/

.seVen-logo-dormant {
  height: 72px;
  opacity: 1;
  pointer-events: none;
  overflow: hidden;
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .seVen-logo {
    animation: seVen-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1c1c23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes seVen-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
